import React, { FC } from 'react'
import classNamesBind from 'classnames/bind'

import css from './styles.module.scss'
const classNames = classNamesBind.bind(css)

interface OwnProps {
  size?: number
}

const CrystalLoader: FC<OwnProps> = ({ size }: OwnProps) => {
  return (
    <div
      className={classNames('container')}
      style={{ width: `${size || 300}px` }}
    >
      <svg x="0px" y="0px" viewBox="0 0 72.6 72.7">
        <path
          className={classNames('outer-1')}
          d="M66.6,56.3C77.7,39.5,73,17,56.3,6C39.5-5,17-0.4,6,16.4c-9.1,13.8-7.7,32,3.4,44.3l-7.9,12l35-0.1l0,0
          C48.6,72.6,59.9,66.4,66.6,56.3z"
        />
        <polygon
          className={classNames('outer-2')}
          points="34.8,41.5 36.3,43.6 37.9,41.5 38.4,40.7 39.3,40.5 41.8,39.8 41.2,37.4 41,36.8 41.2,36.1 41.8,33.7
          39.3,33 38.4,32.8 37.9,32.1 36.3,30 34.8,32.1 34.3,32.8 33.4,33 30.9,33.7 31.5,36.1 31.7,36.8 31.5,37.4 30.9,39.8 33.4,40.5
          34.3,40.7 "
        />
      </svg>
      <div className={classNames('inside')}>
        <svg
          id="Layer_2"
          data-name="Layer 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 73 73"
        >
          <title>Snowflake-Inner</title>
          <polygon
            className={classNames('flake', 'one')}
            points="33.7,29.2 36.3,25.5 39,29.1 43.1,22.1 36.3,15.1 29.5,22.1 "
          />
          <polygon
            className={classNames('flake', 'two')}
            points="46,23.7 41.7,31 45.1,31.9 44.2,35.2 51.3,35.2 55.6,25.8 "
          />
          <polygon
            className={classNames('flake', 'three')}
            points="51.5,38.4 44.2,38.4 45.1,41.6 41.8,42.5 45.9,49.5 55.6,47.3 "
          />
          <polygon
            className={classNames('flake', 'four')}
            points="39.1,44.3 36.3,48 33.6,44.3 29.6,51.1 36.3,58 43,51.1 "
          />

          <polygon
            className={classNames('flake', 'five')}
            points="30.8,42.5 27.6,41.6 28.5,38.4 21.1,38.4 17,47.3 26.8,49.5 "
          />

          <polygon
            className={classNames('flake', 'six')}
            points="28.5,35.2 27.6,31.9 31,31 26.6,23.7 17,25.8 21.3,35.2 "
          />
        </svg>
      </div>
    </div>
  )
}

export default CrystalLoader
