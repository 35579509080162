import React, { ChangeEvent, useEffect, useState } from 'react'
import Link from 'next/link'
import classNamesBind from 'classnames/bind'
import Loader from '@crystal-eyes/components/elements/Loader/Loader'
import VerifiedBadge from '@dashboard/components/VerifiedBadge'
import css from './styles.module.scss'
import { update as updatePhoto } from '@dashboard/store/actions/profile/photo'
import { loadProfile } from '@dashboard/store/actions/profile/show'
import { useAppDispatch } from '@dashboard/hooks/useAppDispatch'
import { useAppStateSelector } from '@dashboard/hooks/useAppStateSelector'
import { usePrevious } from '@dashboard/hooks/usePrevious'
const classNames = classNamesBind.bind(css)

const PlaceholderImage = '/static/share/images/profile-placeholder.svg'
const PlaceholderClearImage =
  '/static/share/images/profile-placeholder-no-background.svg'

interface OwnProps {
  size: number
  photoUrl?: string | null
  fullName?: string
  showNameOnHover?: boolean
  tooltipLeft?: boolean
  profileId?: string | null
  showUpload?: boolean
  uploadPrompt?: boolean
  discType?: string
  linkToProfile?: boolean
  shadow?: boolean
  monochrome?: boolean
  avatar?: boolean
  verified?: boolean
  reloadCallback?: () => void
}

export default function ProfileImage({
  size,
  photoUrl,
  fullName,
  showNameOnHover,
  tooltipLeft,
  profileId,
  showUpload,
  uploadPrompt,
  discType,
  linkToProfile,
  shadow,
  monochrome,
  verified,
  reloadCallback,
}: OwnProps) {
  const [hovered, setHovered] = useState(false)
  const [backup, setBackup] = useState(false)
  const dispatch = useAppDispatch()
  const { photoStates } = useAppStateSelector(state => state.profile)
  const photoState = photoStates[profileId || ''] || 'default'
  const prevPhotoState = usePrevious(photoState)

  useEffect(() => {
    const justUpdated = prevPhotoState !== 'success' && photoState == 'success'
    const justFailed = prevPhotoState !== 'failure' && photoState == 'failure'
    if (justUpdated && profileId && showUpload) {
      reloadCallback ? reloadCallback() : dispatch(loadProfile(profileId))
    } else if (justFailed) alert('Please try again with a PNG or JPG file.')
  }, [photoStates])

  const renderCore = () => {
    if (linkToProfile && profileId) {
      return (
        <Link
          href="/p/[profileId]"
          as={`/p/${profileId}`}
          style={{
            width: size,
            minWidth: size,
            height: size,
            minHeight: size,
          }}
          className={classNames('container', { shadow })}
          passHref
        >
          {renderImage()}
          {renderTooltip()}
          {verified ? renderVerifiedBadge() : null}
        </Link>
      )
    }
    if (showUpload) {
      return (
        <label
          style={{ width: size, minWidth: size, height: size, minHeight: size }}
          className={classNames('container', { shadow })}
          onMouseEnter={_handleMouseEnter}
          onMouseLeave={_handleMouseLeave}
          htmlFor="profile-image"
        >
          {renderImage()}
          {renderUploader()}
          {renderInput()}
          {renderTooltip()}
        </label>
      )
    }
    return (
      <div
        style={{ width: size, minWidth: size, height: size, minHeight: size }}
        className={classNames('container', { shadow })}
      >
        {renderImage()}
        {renderUploader()}
        {renderTooltip()}
        {verified ? renderVerifiedBadge() : null}
      </div>
    )
  }

  const renderVerifiedBadge = () => {
    return (
      <div className={classNames('verified-badge')}>
        <VerifiedBadge size={20} noMargin />
      </div>
    )
  }

  const renderImage = () => {
    if (isValidURL() && photoUrl && !backup) {
      return (
        <img
          src={photoUrl}
          className={classNames('profile-image', { monochrome })}
          alt="profile"
          onError={addBackupPhoto}
        />
      )
    } else {
      return renderBackupPhoto()
    }
  }

  const renderUploader = () => {
    const show = hovered || photoState == 'loading'
    const showUploadPrompt = uploadPrompt && !photoUrl
    const isSmall = size < 80

    if (!showUpload) return null

    if (photoState == 'loading') {
      return (
        <div
          className={classNames('content-wrapper', {
            'has-image': photoUrl,
            show,
            isSmall,
          })}
        >
          <Loader />
        </div>
      )
    }

    return (
      <div
        className={classNames('content-wrapper', {
          'has-image': photoUrl,
          show,
          uploadPrompt: showUploadPrompt,
          isSmall,
        })}
      >
        {photoUrl ? 'Change Profile Image' : 'Upload Profile Image'}
      </div>
    )
  }

  const renderInput = () => {
    return (
      <input
        className={classNames('input')}
        onChange={_handleUpload}
        type="file"
        id="profile-image"
      />
    )
  }

  const renderTooltip = () => {
    if (!showNameOnHover || !fullName) return null

    return (
      <div className={classNames('tooltip', discType, { tooltipLeft })}>
        {fullName}
      </div>
    )
  }

  // Functions
  const _handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0] || !profileId) return

    const file = e.target.files[0]

    dispatch(updatePhoto(profileId, file))
  }

  const _handleMouseEnter = () => {
    setHovered(true)
  }

  const _handleMouseLeave = () => {
    setHovered(false)
  }

  const isValidURL = () => {
    const hasPhotoUrl = photoUrl && photoUrl.length > 0
    const isDataImg = hasPhotoUrl && photoUrl.startsWith('data:image/')
    return hasPhotoUrl && !isDataImg
  }

  const addBackupPhoto = () => {
    setBackup(true)
  }

  const renderBackupPhoto = () => {
    if (discType && fullName) {
      const nameArray = fullName.split(' ')
      const initials = nameArray
        .map(name => {
          return name.slice(0, 1).toUpperCase()
        })
        .join('')

      return (
        <div className={classNames('initial-wrapper', discType)}>
          {initials}
        </div>
      )
    }

    if (discType) {
      return (
        <img
          src={PlaceholderClearImage}
          className={classNames('profile-image', 'background', `${discType}`, {
            monochrome,
          })}
          onError={addDefaultSrc}
        />
      )
    }
    return (
      <img
        src={PlaceholderImage}
        className={classNames('profile-image', { monochrome })}
        onError={addDefaultSrc}
      />
    )
  }

  const addDefaultSrc = (ev: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const target = ev.target as HTMLImageElement

    target.src = PlaceholderImage
  }

  return <>{renderCore()}</>
}
