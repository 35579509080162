import React, { FC } from 'react'
import classNamesBind from 'classnames/bind'
import Loader from '@crystal-eyes/components/elements/Loader/Loader'
import CrystalLoader from '@dashboard/components/CrystalLoader'

import css from './styles.module.scss'
const classNames = classNamesBind.bind(css)

interface OwnProps {
  noPadding?: boolean
  simple?: boolean
  text?: string
  noText?: boolean
  size?: number
  children?: React.ReactNode
}

const LoadingBox: FC<OwnProps> = ({
  noPadding,
  simple,
  text,
  noText,
  size,
  children,
}: OwnProps) => {
  const renderCenter = () => {
    if (simple) {
      return <Loader size={size ?? 64} blue />
    } else {
      return <CrystalLoader size={size ?? 72} />
    }
  }

  // Partials
  const renderLabel = () => {
    if (noText) return null

    return (
      <div className={classNames('label')}>
        {children || text || 'Loading...'}
      </div>
    )
  }

  return (
    <div className={classNames('container', { noPadding })}>
      <div className={classNames('loader')}>
        {renderCenter()}
        {renderLabel()}
      </div>
    </div>
  )
}

export default LoadingBox
